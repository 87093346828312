<template>
  <div class="w-screen min-h-screen md:flex md:justify-center md:items-start lg:items-center dark:bg-gray-800 dark:text-gray-400">
    <div class="relative w-full md:max-w-xl bg-yellow-100 px-16 pb-10 md:rounded-lg shadow-md dark:bg-yellow-900">
      <div class="-ml-16 flex items-center">
        <img class="max-w-xs max-h-16 lg:max-h-24 xl:max-h-32 2xl:max-h-32" :src="companyLogoUrl" alt="Logo">
        <div class="flex flex-col justify-center">
          <h1 class="text-2xl font-semibold leading-6">e-Multiagencja</h1>
          <h2 class="text-xl text-gray-500 font-semibold dark:text-gray-300">resetowanie hasła</h2>
           <span class="text-xs opacity-50">krok 1 z 2</span>
        </div>
      </div>
      <hr class="border-gray-300 -mx-10 mb-2">
      <p class="font-semibold">Podaj adres email w celu przesłania linku resetującego hasło.</p>
      <form @submit.prevent="onSubmit">
        <input
          v-focus
          id="input-1"
          @focus="message = ''"
          ref="email"
          name="email"
          v-model="form.email"
          placeholder="adres email"
          type="email"
          class="tw--form-big my-2 w-full"
          :class="{'border-red-500': message.length > 0 }"
          :disabled="isBusy"
          required
        >
        <button type="submit" class="tw--btn tw--btn-big w-full mt-2" :disabled="isBusy">
          <span v-if="!isBusy">Wyślij</span>
          <span v-else>Wysyłam...</span>
        </button>
        <div class="block text-right mb-4 mt-1">
          <router-link :to="{ name: 'Login' }" class="tw--link">Powrót do strony logowania</router-link>
        </div>
      </form>
      <p class="text-sm" v-html="welcome.content"></p>
      <footer class="text-right font-semibold">{{ welcome.footer }}</footer>

    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService';

export default {
  name: 'PasswordEmail',
  data() {
    return {
      message: '',
      form: {
        email: '',
      },
      isBusy: false,
      welcome: {
        content: `Twoje hasło przetrzymujemy w bazie danych w formie zaszyfrowanej, więc nie możemy Ci go przypomnieć.
            Wpisz do formularza swój adres email powiązany z Twoim kontem, aby otrzymać link resetujący hasło.
            Po otrzymaniu maila postępuj zgodnie z instrukcją.
            <br>
            Jeśli nie znajdziesz wiadomości w skrzynce odbiorczej, sprawdź folder "spam". W razie problemów możesz skontaktować się
            z administratorem w celu ustawienia nowego hasła.`,
        footer: '',
      },
    };
  },
  computed: {
    configParams() {
      return this.$store.getters.configParams;
    },
    companyLogoUrl() {
      let url = this.configParams.company_logo_l;
      if (url === undefined) {
        url = '';
      } else if (url === '') {
        url = '/images/logo/logotyp-emultiagencja-full.png';
      } else {
        url = `/${url}`;
      }
      return url;
    },
  },
  mounted() {
    AuthService.logout();
    this.$store.dispatch('logout');
    this.welcome.footer = this.configParams.company_short_name;
  },
  methods: {
    onSubmit() {
      this.isBusy = true;
      const formData = new FormData();
      formData.append('email', this.form.email);
      AuthService.passwordEmail(formData)
        .then(response => {
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: response.data.msg,
            time: 0,
          });
          this.form.email = '';
          this.isBusy = false;
        })
        .catch(error => {
          if (error.response !== undefined) {
            this.message = error.response.data.msg;
          } else {
            this.message = 'Wystapił nieznany błąd, być może serwer jest zajęty, spróbuj ponownie później';
          }
          this.$store.dispatch('notifications/add', {
            type: 'danger',
            message: this.message,
            time: 0,
          });
          this.isBusy = false;
        });
    },
  },
};
</script>
